body {
  margin: 0px;
  padding: 0;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
  text-align: left;
  justify-content: space-between;
}

.discount {
  margin-top: 5rem;
  margin-bottom: 1rem;
}

footer {
  width: 100vw;
  text-align: center;
  font-size: 0.7rem;
  background-color: rgb(247, 247, 247);
}

a {
  text-decoration: none;
  color: black;
}

.success {
  background: #1f8239;
  padding: 2rem;
  color: white;
  border-bottom: solid black 0.8rem;
  margin-bottom: 5rem;
}

.success a {
  color: white;
  font-size: 1.5rem;
}

.flex-container {
  display:flex;
}

.contact {
  margin-top: 3rem;
}

img {
  width: 30%;
}

.info {
  margin-left: 1.5rem;
  width: 50%;
}


table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
  margin-top: 1.5rem;
}

th, td {
  text-align: left;
  padding: 16px;
}

tr:nth-child(even) {
  background-color: #f2f2f2
}


img {
  display: none;
}

@media only screen and (min-width: 32em) {
  img {
    display: inline;
  }
}

@media only screen and (min-width: 64em) {
  table {
    width: 60%;
  }
}